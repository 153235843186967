:root {
  --bg-color: #000;
  --text-color: #fff;
  --header-bg: #000;
  --about-bg: #111;
  --accent-color: #ff0000;
  --hover-color: #fff;
}

.light-mode {
  --bg-color: #f0f0f0;
  --text-color: #000;
  --header-bg: #fff;
  --about-bg: #e0e0e0;
  --accent-color: #ff0000;
  --hover-color: #ff0000;
}

.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  height: 6px;
  background-color: var(--accent-color);
  z-index: 9999;
  transition: width 0.1s ease;
}

/* General Styles */
body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Header */
.App-header {
  background-color: var(--header-bg);
  padding: 20px;
  padding-top: 24px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-header h1 {
  font-size: 2rem;
  color: var(--text-color);
  margin: 0;
}

.about-me {
  background-color: var(--about-bg);
  padding: 30px;
  margin: 40px auto;
  border-radius: 15px;
  max-width: 800px;
  text-align: left;
  color: var(--text-color);
  box-shadow: 0 4px 6px rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-me:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(255, 0, 0, 0.2);
}

.about-me h2 {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.about-me p {
  line-height: 1.8;
  margin-bottom: 25px;
  font-size: 1.1rem;
  color: var(--text-color);
}

.about-me p:last-child {
  margin-bottom: 0;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-left: 20px;
}

nav a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s;
}

nav a:hover {
  color: var(--accent-color);
}

/* Main Content */
main {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.project {
  margin-bottom: 40px;
}

.project h2 {
  font-size: 2rem;
  color: var(--accent-color);
  margin-bottom: 10px;
}

.project h3 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 10px;
}

.project p {
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Footer */
footer {
  background-color: var(--header-bg);
  color: var(--text-color);
  text-align: center;
  padding: 20px;
}

.github-link {
  text-align: center;
  margin: 20px 0;
}

.github-link a {
  color: var(--accent-color);
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s;
}

.github-link a:hover {
  color: var(--hover-color);
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.social-icon {
  width: 30px;
  height: 30px;
  transition: transform 0.2s;
}

.social-icon:hover {
  transform: scale(1.1);
}

/* Regular Project Images (non-carousel) */
.project-icon {
  max-width: 100%;
  height: auto;
  border: 2px solid var(--accent-color);
  border-radius: 5px;
  transition: transform 0.2s;
}

.project-icon:hover {
  transform: scale(1.01);
}

/* Theme Toggle Button */
.theme-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.theme-toggle:hover {
  color: var(--accent-color);
}

/* Carousel Styles */
.carousel-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 400px; /* Fixed height for carousel */
  overflow: hidden;
}

.carousel-container .image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-container .project-icon {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border: none; /* Remove border for carousel images */
}

.carousel-image-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.carousel-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.dot.active {
  background: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .carousel-container {
    height: 300px; /* Slightly smaller fixed height for mobile */
  }
}